import { RobotSolidIcon } from "../helpers/SvgIcons";

const ChatButton = ({
  setIsChatbotOpen,
  isChatbotOpen,
  company_icon,
  setShowWelcomeModal, // Ensure this is received
  setSessionIdState,
  setSessionStats,
  chatBotID,
}) => {
  const closeWelcomeModal = () => {
    setShowWelcomeModal(false); // Use the function
  };

  const initiateConvo = async () => {
    let sessionId = sessionStorage.getItem("uniqueSessionId");
    setSessionIdState(sessionId);
    if (!sessionId) {
      sessionId = Date.now() + "_" + Math.random().toString(36).substr(2, 9);
      sessionStorage.setItem("uniqueSessionId", sessionId);
      setSessionIdState(sessionId);
      setSessionStats((prevStats) => ({
        ...prevStats,
        sessionId: sessionId,
      }));
    }

    setSessionStats((prevStats) => ({
      ...prevStats,
      sessionId: sessionId,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/initiateConvo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatBotID, sessionId }),
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to initialize the conversation.");
      }
    } catch (error) {
      console.error("Error initializing the conversation:", error);
    }
  };

  const toggleChatbot = () => {
    closeWelcomeModal(); // Close the modal when toggling
    if (!isChatbotOpen) {
      initiateConvo();
    }
    setIsChatbotOpen((prevState) => !prevState);
  };

  return (
    <>
      {/* <span
        className={`chatBtn ${isChatbotOpen ? "open" : ""}`}
        onClick={toggleChatbot}
      >
        {company_icon ? (
          <img src={company_icon} alt={"companyLogo"} />
        ) : (
          <RobotSolidIcon />
        )}
      </span> */}
    </>
  );
};

export default ChatButton;

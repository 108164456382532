import React, { useEffect, useState } from "react";
import ChatbotSkeleton from "./components/ChatbotSkeleton";
import "../src/bootstrap.scss";
import { WebSocketProvider } from "./context/WebSocketContext";

function App() {
  const chatBotID = window.chatbotID;
  const [sessionIdState, setSessionIdState] = useState("");
  const [preloadedData, setPreloadedData] = useState({
    on_off: "",
    welcomeMessage: "",
    Color_primary: "",
    Color_Secondary: "",
    color_button: "",
    employeeName: "",
    department: "",
    gpt_model: "",
    avatarUrl: "",
    logoUrl: "",
    apiKey: "",
    content: "",
    company_email: "",
    companyName: "",
    company_phone: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPreloadedData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/configBychatBotID/${chatBotID}/1`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setPreloadedData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching preloaded data:", error);
      }
    };

    fetchPreloadedData();

    return () => {
      console.log("App component unmounted");
    };
  }, [chatBotID]);

  if (preloadedData[0]?.on_off === 0) {
    return null;
  }
  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <WebSocketProvider
          sessionIdState={sessionIdState && sessionIdState}
          companyEmail={preloadedData[0]?.company_email}
        >
          <ChatbotSkeleton
            setSessionIdState={setSessionIdState}
            sessionIdState={sessionIdState}
            chatBotID={chatBotID}
            preloadedData={preloadedData}
          />
        </WebSocketProvider>
      )}
    </>
  );
}

export default App;

import React, { createContext, useContext, useEffect, useState } from "react";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({
  children,
  companyEmail,
  sessionIdState,
}) => {
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // if (!sessionIdState) return;

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEB_SOCKET}?email=${companyEmail}`
    );

    newSocket.onopen = () => {
      console.log("WebSocket połączony");
      newSocket.send(
        JSON.stringify({ type: "subscribe", topic: "conversationData" })
      );
    };

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [companyEmail, sessionIdState]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

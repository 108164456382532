// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  border: 0;
  height: 1px;
  background-color: var(--rbl-blue-l);
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/css/Divider.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,WAAA;EACA,mCAAA;EACA,cAAA;AACJ","sourcesContent":[".divider {\r\n    border: 0;\r\n    height: 1px;\r\n    background-color: var(--rbl-blue-l);\r\n    margin: 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

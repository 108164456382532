import React, { useEffect, useState } from "react";
import "../css/InteractionStarter.scss";
import {
  MessageEnvelopIcon,
  RobolLogo,
  WhatsAppIcon,
} from "../helpers/SvgIcons";
import Divider from "./Divider";
import { GeneralLoader } from "./loader/GeneralLoader";

const InteractionStarter = ({
  setInteractionStarted,
  toggleChatbot, // add the prop here
  companyEmail,
  companyNumber,
  companyName,
  company_icon,
  logo,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <GeneralLoader logo={company_icon} />;
  }

  return (
    <div className="interactionstarter">
      <div className="interactionstarter-header">
        <img width={30} src={company_icon} alt={"companyLogo"} />
        <h4>{companyName}</h4>
      </div>
      <div className="interactionstarter-body">
        <h2>
          Start een gesprek <br /> met onze chatbot!
        </h2>

        <div className="interactionstarter-card">
          <h4>Hoe kunnen we helpen?</h4>
          <p>Onze AI-chatbot reageert direct 24/7</p>
          <button
            onClick={() => setInteractionStarted(true)}
            className="send-message-button"
          >
            Stuur ons een bericht
          </button>
          <div className="close-underline text-center">
            <small>
              <u onClick={toggleChatbot}>Of sluit dit scherm</u>
            </small>
          </div>
        </div>
        <Divider />
        <div className="interactionstarter-footer">
          <b className="text-center">Neem contact op</b>
          <ul>
            <li>
              <WhatsAppIcon width={24} />
              <a
                href={`https://wa.me/${companyNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </a>
            </li>
            <li>
              <MessageEnvelopIcon width={24} />
              <a
                href={`mailto:${companyEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Email
              </a>
            </li>
          </ul>
        </div>
      </div>

      <p
        className="text-center powered-by"
        onClick={() => window.open("https://www.robol-ai.chat", "_blank")}
      >
        Powered by
        <RobolLogo className="logo-small" />
      </p>
    </div>
  );
};

export default InteractionStarter;

import LogoLoader from "./LogoLoader";
import "../../css/objects3loader.scss";

export const GeneralLoader = ({ logo }) => (
  <div className="center-container">
    <div className="row justify-content-center align-content-center h-100">
      <div className="col-12">
        <LogoLoader src={logo} size="md" />
      </div>
    </div>
  </div>
);

export const availableCompanyUsers = async (companyID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/widget/api/availableCompanyUsers?companyID=${companyID}`
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const forwardOption = async (sessionId, userEmail, forward) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/forwarded/${userEmail}/${forward}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserInfoBySession = async (sessionId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/getUserInfoBySession/${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const closeConversation = async (sessionId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/updateConversationClosed/${sessionId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

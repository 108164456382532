import React from "react";
import ReactDOM from "react-dom/client";
import "./bootstrap.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./components/ErrorBoundry";

const rootElement = document.getElementById("widget-root"); // prod
// const rootElement = document.getElementById('root'); // dev

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  );

  // Ensure proper cleanup on unmount
  if (module.hot) {
    module.hot.dispose(() => {
      root.unmount();
    });
  }
} else {
  console.error("Element with id 'widget-root' not found.");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

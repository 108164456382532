import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "../bootstrap.scss";
import { useWebSocket } from "../context/WebSocketContext";
import "../css/ChatbotBody.scss";
import "../css/ChatbotHeader.scss";
import "../css/ChatbotSkeleton.scss";
import "../css/AvailableUsers.scss";
import {
  CrossIcon,
  SecondaryMinIcon,
  SendIcon,
  SwitchConvoIcon,
} from "../helpers/SvgIcons";
import {
  availableCompanyUsers,
  closeConversation,
  forwardOption,
  getUserInfoBySession,
} from "../services/WidgetServices";
import avatarPlaceHolder from "./avatar-placeholder.png";
import ChatButton from "./ChatButton";
import { EnumForwarding } from "./EnumForwarding";
import { isMobile } from "./helpers";
import InteractionStarter from "./InteractionStarter";
import { TypicLoader } from "./TypingLoader";
import WelcomeModal from "./WelcomeModal";

function ChatbotSkeleton({
  chatBotID,
  preloadedData,
  sessionIdState,
  setSessionIdState,
}) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [interactionStarted, setInteractionStarted] = useState(false);

  const {
    gpt_model,
    color_primary,
    color_secondary,
    color_button,
    employeeName,
    welcomeMessage,
    content,
    companyID,
    company_email,
    companyName,
    company_phone,
    department,
    apiKey,
    avatar,
    company_icon,
  } = preloadedData[0];

  const [conversations, setConversations] = useState([]);
  const [sessionStats, setSessionStats] = useState({
    sessionId:
      sessionStorage.getItem("uniqueSessionId") ||
      `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
    messageCount: 0,
  });
  const [systemMessage, setSystemMessage] = useState({
    role: "system",
    content: content,
  });

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [forwardingRequested, setForwardingRequested] = useState(false);
  const [isForwarded, setIsForwarded] = useState(false);
  const [forwarded_to, setIsForwarded_to] = useState(
    selectedUser?.email || company_email
  );

  //Forwading logic
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);

  const forwardStatus = currentConversation?.forward;

  useEffect(() => {
    const fetchUserData = async () => {
      if (sessionIdState) {
        try {
          const userData = await getUserInfoBySession(sessionIdState);
          setSelectedUser(userData);
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      }
    };
    fetchUserData();
  }, [sessionIdState]);

  const handleUserChange = async (user) => {
    const confirmation = window.confirm(
      "Weet je zeker dat je wilt overstappen naar deze medewerker?"
    );
    if (confirmation) {
      setLoading(true);
      try {
        await forwardOption(
          sessionIdState,
          user.email,
          EnumForwarding.FORWARD_REQUESTED
        );
        setSelectedUser(user);
        setConfirmed(true);
        setIsForwarded(true);
        setLoading(false);
      } catch (error) {
        console.error("Error while forwarding:", error);
        setLoading(false);
      }
    }
  };
  const handleCloseForwarding = async () => {
    if (selectedUser) {
      await forwardOption(
        sessionIdState,
        selectedUser.email,
        EnumForwarding.NOT_FORWARDED
      );
    }
    setIsForwarded(false);
    setForwardingRequested(false);
    setSelectedUser("");
    setConfirmed(false);
    setShowCard(false);
    setLoading(false);
  };
  const handleClose = useCallback(async () => {
    if (selectedUser) {
      await forwardOption(
        sessionIdState,
        selectedUser.email,
        EnumForwarding.NOT_FORWARDED
      );
    }
    setIsForwarded(false);
    setForwardingRequested(false);
    setSelectedUser("");
    setConfirmed(false);
    setShowCard(false);
    setLoading(false);
  }, [selectedUser, sessionIdState]);

  const handleCancelForward = useCallback(async () => {
    if (selectedUser) {
      await forwardOption(
        sessionIdState,
        selectedUser.email,
        EnumForwarding.NOT_FORWARDED
      );
    }
    setSelectedUser("");
    setIsForwarded(false);
    setForwardingRequested(false);
    setConfirmed(false);
    setShowCard(false);
    setLoading(false);
  }, [selectedUser, sessionIdState]);

  const handleSwitchConvo = useCallback(() => {
    setSelectedUser("");
    setForwardingRequested(true);
    setShowCard(false);
  }, []);

  useEffect(() => {
    if (forwardingRequested && !confirmed) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [forwardingRequested, confirmed]);

  useEffect(() => {
    if (!loading && !showCard && forwardingRequested) {
      setShowCard(true);
    }
  }, [loading, showCard, forwardingRequested]);

  const socket = useWebSocket();

  useEffect(() => {
    const fetchCompanyUsers = async () => {
      try {
        const responseData = await availableCompanyUsers(companyID);
        if (responseData.success && Array.isArray(responseData.data)) {
          setCompanyUsers(responseData.data);
        } else {
          console.error("Received invalid data format:", responseData);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      }
    };
    fetchCompanyUsers();
  }, [companyID]);

  useEffect(() => {
    if (!socket) return;

    const handleWebSocketMessages = (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        if (
          receivedData.type === "conversationData" &&
          Array.isArray(receivedData.data)
        ) {
          setConversations(
            receivedData.data.sort(
              (a, b) => new Date(b.started_at) - new Date(a.started_at)
            )
          );
        } else {
          console.error(
            "Received data is not in the expected format:",
            receivedData
          );
        }
      } catch (error) {
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.onmessage = handleWebSocketMessages;

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color",
      color_primary
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      color_secondary
    );
    document.documentElement.style.setProperty("--color-button", color_button);
  }, [color_primary, color_secondary, color_button]);

  useEffect(() => {
    let sessionId = sessionStorage.getItem("uniqueSessionId");
    if (!sessionId) {
      sessionId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      sessionStorage.setItem("uniqueSessionId", sessionId);
      setSessionStats((prevStats) => ({ ...prevStats, sessionId }));
    }
  }, []);

  useEffect(() => {
    const currentConvo = conversations.find(
      (convo) => convo.sessionId === sessionIdState
    );
    setCurrentConversation(currentConvo);
  }, [conversations, sessionIdState]);

  // const handleUserChange = useCallback((user) => {
  //   setSelectedUserName(user.first_name);
  //   setIsForwarded_to(user.email);
  //   setSelectedUser(user.email);
  // }, []);

  const handleMessageChange = useCallback(
    (e) => setMessage(e.target.value),
    []
  );

  useEffect(() => {
    if (isChatbotOpen && messages.length > 0) {
      const botMessagesCount = messages.filter(
        (message) => message.sender !== "user"
      ).length;
      setSessionStats((prevStats) => ({
        ...prevStats,
        messageCount: botMessagesCount,
      }));
    }
  }, [isChatbotOpen, messages]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/configBychatBotID/${chatBotID}/${sessionStats?.sessionId}`
    );
    setSystemMessage({ role: "system", content });
    setMessages((prevMessages) => [
      { ...prevMessages[0], message: welcomeMessage },
      ...prevMessages.slice(1),
    ]);
  }, [chatBotID, content, sessionStats?.sessionId, welcomeMessage]);

  useEffect(() => {
    const currentConvo = conversations.find(
      (convo) => convo.sessionId === sessionIdState
    );
    if (currentConvo) {
      setMessages(
        currentConvo.conversation_data.map((msg) => ({
          message: msg.content,
          sender:
            msg.role === "user"
              ? "user"
              : msg.role === "assistant"
              ? "assistant"
              : "remote worker",
        }))
      );
    }
  }, [conversations, sessionIdState]);

  useEffect(() => {
    setShowWelcomeModal(true);
  }, []);

  const updateConversation = useCallback(
    async (sessionId, updatedConversationData) => {
      const conversationData = updatedConversationData.map((message) => ({
        role: message.sender !== "user" ? "assistant" : "user",
        content: message.message,
      }));

      try {
        await fetch(
          `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/updateConversation/${forwarded_to}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, conversationData }),
          }
        );
      } catch (error) {
        console.error("Error updating conversation:", error);
      }
    },
    [forwarded_to]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!message.trim()) return;

      const userMessage = { message, sender: "user" };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);

      const apiRequestBody = {
        chatBotID,
        chatMessages: updatedMessages.map((msg) => ({
          role: msg.sender === "user" ? "user" : "assistant",
          content: msg.message,
        })),
        systemMessage,
        gpt_model,
        API_KEY: apiKey,
        content,
        forward: isForwarded ? 1 : 0,
        forwarded_to,
        sessionId: sessionIdState,
      };

      setIsTyping(true);
      setMessage("");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/process-message/${forwarded_to}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(apiRequestBody),
          }
        );

        if (!response.ok) throw new Error("API call failed.");
        const data = await response.json();
        const newMessages = [
          ...updatedMessages,
          { message: data.message, sender: employeeName },
        ];

        await updateConversation(sessionIdState, newMessages);
        setConversations((prevConversations) => {
          const updatedConversations = [...prevConversations];
          const conversationIndex = updatedConversations.findIndex(
            (convo) => convo.sessionId === sessionIdState
          );
          if (conversationIndex !== -1) {
            updatedConversations[conversationIndex].conversation_data =
              newMessages.map((msg) => ({
                role: msg.sender !== "user" ? "assistant" : "user",
                content: msg.message,
              }));
          }
          return updatedConversations;
        });
        setMessages(newMessages);
      } catch (error) {
        console.error("Error in processing message:", error);
      } finally {
        setIsTyping(false);
      }
    },
    [
      message,
      messages,
      chatBotID,
      systemMessage,
      gpt_model,
      apiKey,
      content,
      isForwarded,
      forwarded_to,
      sessionIdState,
      employeeName,
      updateConversation,
    ]
  );

  const toggleChatbot = useCallback(() => {
    setShowWelcomeModal(false);
    if (!isChatbotOpen) initiateConvo();
    window.parent.postMessage(
      isChatbotOpen ? "chatbot-close" : "chatbot-open",
      "*"
    );
    setIsChatbotOpen((prevState) => !prevState);
  }, [isChatbotOpen]);

  const initiateConvo = useCallback(async () => {
    let sessionId = sessionStorage.getItem("uniqueSessionId");
    if (!sessionId) {
      sessionId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      sessionStorage.setItem("uniqueSessionId", sessionId);
      setSessionIdState(sessionId);
      setSessionStats((prevStats) => ({ ...prevStats, sessionId }));
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/initiateConvo`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ chatBotID, sessionId }),
        }
      );
      if (response.status !== 200)
        throw new Error("Failed to initialize the conversation.");
    } catch (error) {
      console.error("Error initializing the conversation:", error);
    }
  }, [chatBotID, setSessionIdState]);

  function formatMessage(message) {
    const productPattern = /(\d+\.\s.*?)(?=(\d+\.\s|$))/gs;

    // Usuń informacje o produktach z wiadomości
    message = message.replace(productPattern, "");

    const urlPattern = /(https?:\/\/[^\s\)]+)(\))?/g;
    const boldPattern = /\*\*(.*?)\*\*/g;
    const sourcePattern = /【\d+:\d+†source】/g;

    // Usuń zbędne elementy, jak "source" i podwójne gwiazdki
    message = message.replace(sourcePattern, "");
    message = message.replace(/\*\*/g, "");

    // Zastosuj pogrubienie
    message = message.replace(boldPattern, "<strong>$1</strong>");

    // Zrób z URL klikalne linki
    message = message.replace(urlPattern, (match, url) => {
      if (url.endsWith(")")) {
        url = url.slice(0, -1);
      }
      return ` <br />- <a href="${url}" target="_blank" style="color: #00FF00;">${url}</a><br /> `;
    });

    return message.trim();
  }

  function renderProductCards(message) {
    const productPattern = /(\d+\.\s.*?)(?=(\d+\.\s|$))/gs;
    const products = [...message.matchAll(productPattern)];

    if (products.length === 0) {
      return null;
    }

    return (
      <div className="row g-3">
        {products.map((productMatch, index) => {
          const productText = productMatch[0];

          // Extract product details
          let title = productText.match(/^\d+\.\s(.*)/);
          title = title
            ? title[1].split("\n")[0].trim().replace(/\*\*/g, "")
            : ""; // Remove asterisks

          const priceMatch = productText.match(/- Prijs: (.*)/);
          const price = priceMatch ? priceMatch[1].trim() : "";

          // Correct image URL extraction
          const imageMatch = productText.match(/https?:\/\/[^\s\)]+/g);
          let imageUrl = imageMatch ? imageMatch[0].trim() : "";

          // Filter out unwanted images like loaders or placeholders
          if (imageUrl.includes("loader") || imageUrl.includes("placeholder")) {
            imageUrl = ""; // Remove incorrect image URLs
          }

          // Remove "source" and any links from the description
          const sourcePattern = /【\d+:\d+†source】/g;
          const urlPattern = /\[.*?\]\(https?:\/\/[^\s]+\)/g;

          const descriptionMatch = productText
            .replace(sourcePattern, "") // Remove "source"
            .replace(urlPattern, "") // Remove all URLs from description
            .match(/- (?!Prijs|Maten|Afbeelding|Zdjęcie)(.*)/g);

          const description = descriptionMatch
            ? descriptionMatch
                .map((line) => line.replace(/- /, "").trim())
                .join("<br />")
            : "";

          // Bootstrap classes: 2 products per row (col-md-6)
          const colClass = products.length === 1 ? "col-md-12" : "col-md-6";

          return (
            <div
              key={index}
              className={`${colClass} product-card`}
              style={{
                marginBottom: "15px",
              }}
            >
              <div
                className="card-container"
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  minHeight: "400px", // Minimum height for all cards to accommodate text
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                {imageUrl && (
                  <div
                    style={{
                      flexShrink: 0,
                      height: "180px", // Fixed height to ensure uniformity across cards
                      width: "100%",
                      borderRadius: "8px",
                      overflow: "hidden",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt={title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Make sure the image covers the entire container without being cut off
                      }}
                    />
                  </div>
                )}
                <h4 style={{ fontSize: "1rem", marginTop: "10px" }}>{title}</h4>
                {description && (
                  <p
                    className="description-text"
                    style={{
                      color: "#666",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 4, // Increased to 4 lines before truncating
                      WebkitBoxOrient: "vertical",
                      cursor: "pointer", // Indicate more information available on hover
                    }}
                    title={description.replace(/<br \/>/g, "\n")} // Show full text on hover
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                )}
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#333",
                    marginTop: "auto",
                  }}
                >
                  {price}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <WelcomeModal
        showWelcomeModal={showWelcomeModal}
        setShowWelcomeModal={setShowWelcomeModal}
        welcomeMessage={welcomeMessage}
      />
      <div className="my-chatbot">
        <div className={`botIcon ${isChatbotOpen ? "showBotSubject" : ""}`}>
          {(!isMobile || !isChatbotOpen) && !isChatbotOpen && (
            <div className="chatbot-container robolCss">
              <ChatButton
                isChatbotOpen={isChatbotOpen}
                setIsChatbotOpen={setIsChatbotOpen}
                setShowWelcomeModal={setShowWelcomeModal}
                company_icon={company_icon}
                setSessionIdState={setSessionIdState}
                setSessionStats={setSessionStats}
                chatBotID={chatBotID}
              />
            </div>
          )}

          {isChatbotOpen && (
            <div className="Layout Layout-open Layout-expand Layout-right">
              {!interactionStarted ? (
                <InteractionStarter
                  companyNumber={company_phone}
                  companyName={companyName}
                  companyEmail={company_email}
                  company_icon={company_icon}
                  setIsChatbotOpen={setIsChatbotOpen}
                  setInteractionStarted={setInteractionStarted}
                  toggleChatbot={toggleChatbot}
                />
              ) : (
                <div className="Messenger_messenger">
                  <div className="Messenger_header">
                    <div className="header-content">
                      {/* Avatar and User Information */}
                      <div className="header-content d-flex justify-content-between align-items-center">
                        <div className="header-left">
                          {/* If Forwarded */}
                          {forwardStatus === EnumForwarding.IS_FORWARDED ? (
                            <>
                              <div
                                className={`header-left ${
                                  confirmed ? "slide-in" : "slide-out"
                                }`}
                              >
                                {/* Forwarded User Avatar */}
                                <div
                                  className="avatar_very_small avtr selected" // Keeping "selected" class for blinking green effect
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundImage: `url(${
                                      selectedUser?.avatarUrl ||
                                      avatarPlaceHolder
                                    })`, // Replace with the forwarded user's avatar URL if available
                                  }}
                                ></div>

                                {/* Forwarded User Details */}
                                <div className="persona_details">
                                  <div className="persona_name">
                                    {selectedUser?.first_name}{" "}
                                    {selectedUser?.last_name}
                                  </div>
                                  <div className="persona_status">
                                    {selectedUser?.department ||
                                      "Klantenservice"}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            // Default Floortje Example
                            <>
                              <div
                                className="avatar_very_small avtr"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                  backgroundImage:
                                    "url(https://www.robol-ai.chat/wp-content/uploads/2024/06/Afbeelding-van-WhatsApp-op-2024-06-23-om-21.49.16_0495631b.jpg)",
                                }}
                              ></div>
                              <div className="persona_details">
                                <div className="persona_name">
                                  Michal | {companyName}
                                </div>
                                <div className="persona_status">
                                  Productexpert
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* Minimize and Forward Icons */}
                      <div className="header-right">
                        {/* SwitchConvoIcon */}
                        {!forwardingRequested &&
                          !confirmed &&
                          forwardStatus !== EnumForwarding.IS_FORWARDED &&
                          messages.length >= 3 && (
                            <span
                              className="chat_forward_icon pr-5"
                              onClick={handleSwitchConvo}
                              title="Medewerker spreken"
                            >
                              <SwitchConvoIcon fill="black" width="20px" />
                            </span>
                          )}
                        <span
                          className="chat_minimize_icon"
                          onClick={toggleChatbot}
                        >
                          <SecondaryMinIcon fill="black" width="20px" />
                        </span>
                      </div>

                      {/* </div> */}
                      {/* Confirm Modal */}
                      {showConfirmModal && (
                        <div className="chat_modal">
                          <div className="chat_modalcontent">
                            <p>
                              Weet je zeker dat je deze gesprek wilt afsluiten?
                            </p>
                            <button
                              className="customBtnSecondary w-25"
                              onClick={async () => {
                                try {
                                  await closeConversation(sessionIdState);
                                  setInteractionStarted(false);
                                  setIsForwarded(false);
                                } catch (error) {
                                  console.error(
                                    "Error handling conversation:",
                                    error
                                  );
                                }
                                setMessages([
                                  {
                                    message: "Hallo!",
                                    sentTime: "paar seconden geleden",
                                    sender: "bot",
                                  },
                                ]);
                                setSessionStats({
                                  sessionId: null,
                                  messageCount: 0,
                                });
                                sessionStorage.removeItem("uniqueSessionId");
                                setShowConfirmModal(false);
                                toggleChatbot();
                                setForwardingRequested(false);
                              }}
                            >
                              Ja
                            </button>
                            <button
                              className="customBtnPrimary w-25"
                              onClick={() => setShowConfirmModal(false)}
                            >
                              Nee
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {forwardStatus === EnumForwarding.NOT_FORWARDED &&
                    forwardingRequested &&
                    !confirmed && (
                      <div
                        className={`pb-3 container text-center ${
                          showCard ? "slide-in" : "slide-out"
                        }`}
                      >
                        {loading && (
                          <>
                            <Spinner
                              className="mt-4"
                              animation="grow"
                              variant="primary"
                            />
                            <p>Even kijken wie beschikbaar is ⌛</p>
                          </>
                        )}

                        {!loading && (
                          <>
                            <div className="close-icon" onClick={handleClose}>
                              <CrossIcon fill="#000" width="20px" />
                            </div>
                            <h4 style={{ color: "black" }} className="pt-3">
                              {companyName}
                            </h4>
                            <p style={{ color: "black" }}>
                              Beschikbare medewerkers
                            </p>
                            <hr />
                            {companyUsers.length === 0 ? (
                              <>
                                <p>Op dit moment is er niemand beschikbaar.</p>
                                <br />
                                <small>
                                  U kunt ons ook bereiken op:{" "}
                                  {/* <a href={`mailto:${companyEmail}`}>
                                    <u>{companyEmail}</u>
                                  </a> */}
                                </small>
                              </>
                            ) : (
                              <div
                                className={`row ${
                                  showCard ? "visible" : "hidden"
                                }`}
                              >
                                {companyUsers.slice(0, 3).map((user, index) => (
                                  <div
                                    key={index}
                                    className="available-user col-md"
                                    onClick={() => handleUserChange(user)}
                                  >
                                    <div className="avatar-header text-center">
                                      <span className="avtr avtr-companyuser">
                                        <img
                                          src={company_icon}
                                          alt="avatar"
                                          className={`avatar-image mt-3 ${
                                            selectedUser &&
                                            selectedUser.email === user.email
                                              ? "selected"
                                              : ""
                                          }`}
                                        />
                                      </span>
                                    </div>
                                    <div className="user-details mt-1">
                                      <b>{user.first_name}</b>
                                      <br />
                                      <span
                                        className={`role ${user.role.toLowerCase()}`}
                                      >
                                        {user.role === "ADMIN"
                                          ? "Eigenaar"
                                          : "Klantenservice"}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                        <hr />
                      </div>
                    )}

                  {forwardStatus === EnumForwarding.FORWARD_REQUESTED &&
                    forwardingRequested && (
                      <div
                        className={`pb-3 container text-center "slide-in" : "slide-out"
}`}
                      >
                        <>
                          <Spinner
                            className="mt-4"
                            animation="grow"
                            variant="primary"
                          />
                          <p>
                            Wachten tot dat <b>{selectedUser?.first_name}</b> je
                            verzoek accepteert⌛
                          </p>
                        </>
                      </div>
                    )}
                  {forwardStatus === EnumForwarding.REJECTED && confirmed && (
                    <>
                      <p>
                        Forwarding rejected, try again or talk to our AI bot.
                      </p>
                      <div
                        className="header-right"
                        title="Verbinding verbreken"
                      >
                        <CrossIcon
                          onClick={() => handleCancelForward()}
                          className="switch-icon"
                          fill="red"
                          width="35"
                          height="35"
                        />
                      </div>
                    </>
                  )}

                  <div className="Messenger_content">
                    <div className="Messages">
                      <div className="Messages_list">
                        {messages.map((msg, index) => {
                          const formattedMessage = formatMessage(msg.message);
                          const productCards = renderProductCards(msg.message);

                          return (
                            <React.Fragment key={index}>
                              {/* Wiadomość */}
                              <div
                                className={`msg ${
                                  msg.sender === "user" ? "user" : "assistant"
                                }`}
                              >
                                {msg.sender !== "user" && (
                                  <div className="bot-avatar-container"></div> // Avatar asystenta
                                )}
                                <span
                                  className={
                                    msg.sender === "user"
                                      ? "userResponseText slide-from-right"
                                      : "responsText slide-from-left"
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: formattedMessage,
                                  }}
                                ></span>
                              </div>

                              {/* Sekcja Produktów */}
                              {productCards && (
                                <div className="container product-cards-container mt-3 mb-4">
                                  <div className="row">{productCards}</div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}

                        {isTyping && (
                          <div className="msg assistant">
                            <div className="bot-avatar-container"></div>
                            <div
                              className="responsText slide-from-left"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px 15px 15px 5px",
                              }}
                            >
                              <TypicLoader />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Footer form for user input */}
                    <form id="messenger" onSubmit={handleSubmit}>
                      <div className="Input Input-blank">
                        <input
                          name="msg"
                          className="Input_field"
                          placeholder="Bericht versturen..."
                          value={message}
                          onChange={handleMessageChange}
                        />
                        <button
                          type="submit"
                          className="Input_button Input_button-send"
                        >
                          <div className="Icon">
                            <SendIcon />
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatbotSkeleton;
